import { FaSearch, FaPalette, FaCode, FaRocket } from 'react-icons/fa';
import './ourProcess.css';

const OurProcess = () => {
  return (
    <div className="ourprocess-container">
      <div className="ourprocess-title-container">
        <h1>
          A carefully crafted process
          <span className="bluedot"></span>
        </h1>
        <div className="blueUnderline"></div>
        <div className="ourprocess-paragrah">
          <p>A methodical execution that ensures precision and effectiveness.</p>
        </div>
      </div>
      <div className="ourprocess-content-container">
        <div className="process-content-item">
          <div className="icon"> <FaSearch /> </div>
          <div className="process-content-item-header">
            <h2>Discover</h2>
            <p>
              First we listen and 'strip down' your idea to reveal the core of your app's value proposition.
            </p>
          </div>
        </div>

        <div className="process-content-item">
          <div className="icon">  <FaPalette />  </div>
          <div className="process-content-item-header">
            <h2>Design</h2>
            <p>
              We create a lean go-to-market product strategy that best supports the user's experience.
            </p>
          </div>
        </div>

        <div className="process-content-item">
          <div className="icon">  <FaCode /> </div>
          <div className="process-content-item-header">
            <h2>Develop</h2>
            <p>
              Our in-house, US-based dev team works with our creative team to code with precision.
            </p>
          </div>
        </div>

        <div className="process-content-item">
          <div className="icon"><FaRocket /> </div>
          <div className="process-content-item-header">
            <h2>Deploy</h2>
            <p>
              Real artists ship. We partner with you to launch and iterate to find true product-market fit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
