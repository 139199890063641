import React, { useState } from "react";
import iphone from "../../Assets/iphone.jpeg";
import iphone2 from "../../Assets/iphone2.jpeg";
import slider4 from "../../images/slider4.jpg";
import slider5 from "../../images/slider5.jpg";
import './common.css';  // Assuming you have a CSS file for styling

const ImageSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    
    const slides = [
        {
            image: iphone2,
            title: "1 Innovative Solutions for Real-World Challenges",
            description: "We craft tailored software solutions that address your business's unique challenges, helping you stay ahead of the competition through innovation and technology."
        },
        {
            image: iphone,
            title: "User-Centric Design, Seamless Experience",
            description: "Our approach focuses on intuitive user interfaces and seamless experiences that keep your customers engaged and satisfied, driving higher retention and growth."
        },
        {
            image: slider4,
            title: "Agile Development for Rapid Results",
            description: "With agile methodologies, we ensure flexibility and speed, delivering functional products quickly while adapting to your evolving needs."
        },
        {
            image: slider5,
            title: "Scalable Software to Support Your Growth",
            description: "We build robust, scalable software designed to grow with your business, ensuring long-term success and adaptability in an ever-changing market."
        }
    ];

    const prevSlide = () => {
        const newIndex = currentSlide === 0 ? slides.length - 2 : currentSlide - 2;
        setCurrentSlide(newIndex < 0 ? 0 : newIndex);
    };

    const nextSlide = () => {
        const newIndex = currentSlide + 2 >= slides.length ? 0 : currentSlide + 2;
        setCurrentSlide(newIndex);
    };

    return (
        <div className="slider">
            <div className="slider-contentent-wrapper">
            <div className="slider-h1-container">
               <h2>#1 Innovative Solutions <br/>for Real-World Challenges<span className="colored-dot"></span> </h2>
            </div>
            <div className="slider-paragraph-container"> <p>{slides[currentSlide].description}</p> </div>
                    <div className="slider-button-container">
                        <a className="slider-button-container-a" href="">Lets Talk</a>
                    </div>

            {/* Images and Button */}
            <div className="slider-left-image">
                <img id="leftimage" src={slides[currentSlide].image} alt={`Slide ${currentSlide}`} className="slider-image" />
            </div>
            <div className="slider-right-image">
                    <img src={slides[currentSlide + 1].image} alt={`Slide ${currentSlide + 1}`} className="slider-image" />
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
