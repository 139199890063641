import React from 'react';
import './logos.css';

const CompanyLogos = () => {
  const logos = [
    'https://themusebylvg.com/wp-content/uploads/2021/11/THE-MUSE-BLACK-site-1.png',
    'https://www.henkel.com/resource/blob/737324/1129f40d0df611e51758a0d35e6cab78/data/henkel-logo-standalone-svg.svg',
    'https://unarenabylvg.com/wp-content/uploads/2024/02/un-arena.png',
    'https://www.g4s.com/en-ng/-/media/g4s/global/images/logos/g4s---an-allied-universal-company-logo/g4s_one-company_logo_180x136.ashx',
    'https://halogen-group.com/assets/images/newlogo1.png',
    'https://www.ambev.com.br/sites/g/files/wnfebl10941/files/styles/webp/public/ambev_azul%202%201_1.png.webp?itok=MAV1e6AK',
    'https://www.femsa.com/wp-content/uploads/2019/07/femsa.png',
    'https://www.petrochina.com.cn/ptr/xhtml/images/index01.jpg',
    'https://www.narscosmetics.com/on/demandware.static/-/Library-Sites-NARS-Shared-Library/default/dw22752b51/images/NARS_SU24_30thAnniversary_HP_Logo.png',
    'https://www.boots.com/wcsstore/eBootsStorefrontAssetStore/MasterLogo2019/BVI%20Logo%20v2.png'
  ];

  return (
    <div className="company-logos-container">
      {logos.map((logo, index) => (
        <img key={index} src={logo} alt={`Company Logo ${index + 1}`} className="company-logo" />
      ))}
    </div>
  );
};

export default CompanyLogos;
