import React from 'react';
import './counter.css'; // Import the CSS file

const CounterSection = () => {
  return (
    <div className="counter-section">
      <div className="counter-item">
        <div className="counter-inner">
          <div className="counter">
            <span className="digit d3">3</span>
            <span className="char">.</span>
            <span className="digit d7">7</span>
            <span className="char">M</span>
          </div>
          <h4 className="counter-title">App Downloads</h4>
        </div>
      </div>

      <div className="counter-item">
        <div className="counter-inner">
          <div className="counter">
            <span className="char">$</span>
            <span className="digit d9">9</span>
            <span className="digit d6">6</span>
            <span className="char">.</span>
            <span className="digit d5">5</span>
          </div>
          <h4 className="counter-title">App-Funds Raised</h4>
        </div>
      </div>

      <div className="counter-item">
        <div className="counter-inner">
          <div className="counter">
            <span className="digit d4">4</span>
            <span className="char">,</span>
            <span className="digit d6">6</span>
            <span className="digit d7">7</span>
            <span className="digit d9">9</span>
          </div>
          <h4 className="counter-title">Cups of Coffee</h4>
        </div>
      </div>

      <div className="counter-item">
        <div className="counter-inner">
          <div className="counter">
            <span className="digit d1">1</span>
            <span className="char">.</span>
            <span className="digit d2">2</span>
            <span className="char">B</span>
          </div>
          <h4 className="counter-title">High Fives</h4>
        </div>
      </div>

      <div className="counter-item">
        <div className="counter-inner">
          <div className="counter">
            <span className="digit d3">3</span>
            <span className="digit d2">2</span>
            <span className="digit d7">7</span>
          </div>
          <h4 className="counter-title">Apps Launched</h4>
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
