import React, { useState } from 'react';
import { auth } from '../firebasekeys';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import "./common.css";
import { ClipLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [spiner, setSpiner] = useState(false);

    const handleLogin = () => {
        if (!email || !password) {
            toast.error('Kindly check your email & password and try again', { autoClose: 7000 });
            setSpiner(false);
        } else {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    localStorage.setItem("user", JSON.stringify(user));
                    setSpiner(false);
                    navigate('/addPost');
                    window.location.reload();
                    toast.success("Login successful", { autoClose: 7000 });
                })
                .catch((error) => {
                    setSpiner(false);
                    toast.error(error.message === "Firebase: Error (auth/internal-error)." ? "Kindly check your network connection and try again" : "Kindly check your PASSWORD & EMAIL address", { autoClose: 7000 });
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                });
        }
    };

    return (
        <div className='login'>
            <form>
                <h3>LOGIN WITH EMAIL</h3>
                {spiner ? (
                    <div className='spinner'>
                        <ClipLoader color={'#123abc'} loading={true} />
                    </div>
                ) : null}
                <div className='form-group' id='login'>
                    <input
                        placeholder='Email'
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <input
                        placeholder='Password'
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="col">
                    <button
                        type="button"
                        onClick={() => {
                            setSpiner(true);
                            handleLogin();
                        }}
                        className=""
                    >
                        Submit
                    </button>
                </div>
            </form>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} /> {/* Toast container */}
        </div>
    );
}

export default Login;
