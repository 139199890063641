import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../Assets/logo/logo.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookF } from '@fortawesome/free-brands-svg-icons';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false); // Track scroll position

    const navigate = useNavigate();

    // Toggle the menu visibility
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    // Scroll listener to adjust header behavior
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setScrolled(true); // Activate scroll effect when scrolled more than 20px
            } else {
                setScrolled(false); // Reset scroll effect when at the top
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {/* Contact Ribbon */}
            <div className="contact-ribbon">
                <a href="https://wa.me/2349030545602" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} className="contact-icon" />
                </a>
                <a href="https://www.facebook.com/people/Sapphire-blue-Software-Empire-Limited/100087989894112/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebookF} className="contact-icon" />
                </a>
                <a href="tel:+2347054454910" className="contact-item">
                    <FontAwesomeIcon icon={faPhone} /> <span>+2347054454910</span>
                </a>
                <a href="mailto:sapphirebluesoft@gmail.com" className="contact-item">
                    <FontAwesomeIcon icon={faEnvelope} /> <span>sapphirebluesoft@gmail.com</span>
                </a>
                <a href="https://www.google.com/maps?q=Plot+12,+Humponu+Wusu,+Lekki" target="_blank" rel="noopener noreferrer" className="contact-item">
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> <span>Plot 12, Humponu Wusu, Lekki</span>
                </a>
            </div>

            {/* Navbar */}
            <nav className={`header-maincontainer ${scrolled ? 'scrolled' : ''}`}>
                <img onClick={() => navigate("/")} className='logo' src={logo} alt="company logo" />
                
                {/* Menu icon */}
                <FontAwesomeIcon onClick={handleMenuToggle} className="link-menu" icon={faBars} />

                {/* Add 'open' class when menuOpen is true */}
                <div className={`header-links ${menuOpen ? 'open' : ''}`}>
                    <Link className="link-text" to="/blog"><li>SKILL STACK</li></Link>
                    <Link className="link-text" to="/services"><li>OUR WORK</li></Link>
                    <Link className="link-text" to="/"><li>MEDIA</li></Link>
                    <Link className="link-text" to="/about"><li>ABOUT US</li></Link>
                    <Link className="link-text" to="/"><li>LET`S TALK</li></Link>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
