import React, { useState } from 'react';
import "./home.css";
import ImageSlider from '../common/Slider';
import Counters from '../common/Counter';
import OurProcess from '../common/OurProcess';
import CompanyLogos from '../common/Logos';


const Home =()=>{
    return (
        <div className='home'>

            <div className='landingpage'>
               <ImageSlider />
            </div>
            <div className='counter-area'>
                <Counters />
            </div>
            <div className='process-container'>
               <OurProcess />
            </div>
            <div className='partners-container'>
                <CompanyLogos />
            </div>
        </div>
    );
}

export default Home;
