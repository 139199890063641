import { useState } from 'react';
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';
import { storage } from "../firebasekeys";
import { db } from "../firebasekeys";
import { push, set, serverTimestamp } from 'firebase/database';
import { ref as re } from 'firebase/database';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import "../common/common.css";
import "./Blog.css";

const AddPost = () => {
  const [category, setCategories] = useState("General");
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState("");
  const [subtitles, setSubtitles] = useState([{ text: '', paragraphs: [''] }]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // category selector
  function CategorySelector({ categories, onChange }) {
    const handleCategoryChange = (event) => {
      const selectedCategory = event.target.value;
      onChange(selectedCategory);
    };

    return (
      <div>
        <label htmlFor="category-select">Select a category:</label>
        <select id="category-select" onChange={handleCategoryChange}>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
    );
  }

  const categories = ["General", "Technology", "Programming", "Design"];

  const handleCategoryChange = (selectedCategory) => {
    setCategories(selectedCategory)
  };

  function handleAddSubtitle() {
    setSubtitles([...subtitles, { text: '', paragraphs: [''] }]);
  }

  function handleAddParagraph(index) {
    const updatedSubtitles = [...subtitles];
    updatedSubtitles[index].paragraphs.push('');
    setSubtitles(updatedSubtitles);
  }

  function handleParagraphChange(subtitleIndex, paragraphIndex, value) {
    const updatedSubtitles = [...subtitles];
    updatedSubtitles[subtitleIndex].paragraphs[paragraphIndex] = value;
    setSubtitles(updatedSubtitles);
  }

  function handleImageUpload(event, subtitleIndex, paragraphIndex) {
    const file = event.target.files[0];
    const storageRef = ref(storage, file.name);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        const updatedImageUrls = [...imageUrls];
        updatedImageUrls.push(downloadURL);
        setImageUrls(updatedImageUrls);
        const updatedSubtitles = [...subtitles];
        updatedSubtitles[subtitleIndex].paragraphs[paragraphIndex] = `${updatedSubtitles[subtitleIndex].paragraphs[paragraphIndex]} <img src="${downloadURL}" alt="uploaded image">`;
        setSubtitles(updatedSubtitles);
      });
    });
  }

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (title.trim() === '') {
      setIsLoading(false);
      toast.info("Kindly add a title for your post", { autoClose: 7000 });
      return;
    }
    for (const subtitle of subtitles) {
      if (subtitle.text === '') {
        setIsLoading(false);
        toast.info("Kindly add a subtitle for your post", { autoClose: 7000 });
        return;
      }
      for (const paragraph of subtitle.paragraphs) {
        if (paragraph.trim() === '') {
          setIsLoading(false);
          toast.info("Kindly type some text into the paragraph", { autoClose: 7000 });
          return;
        }
      }
    }
    // Create the post
    const post = {
      description: description,
      category: category,
      title: title,
      subtitles: subtitles,
      timestamp: serverTimestamp(),
    };
    const newPostRef = push(re(db, 'blog-posts'));
    try {
      await set(newPostRef, post);
      setIsLoading(false);
      toast.success("Your post has been published successfully!", { autoClose: 7000 });
      console.log('New blog post added successfully.', post);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message === "Failed to fetch" ? "Kindly check your network connection and try again!" : error.message, { autoClose: 7000 });
      console.error('Error adding new blog post:', error);
    } finally {
      // Reset the form
      setTitle('');
      setSubtitles([{ text: '', paragraphs: [''] }]);
    }
  };

  return (
    <div className='addpost'>
      <form id='addpoststay'>
        <h3>NEW POST</h3>
        <div className="form-group">
          <CategorySelector categories={categories} onChange={handleCategoryChange} />
          <label>
            Description:
            <input type="text" value={description} onChange={(event) => setDescription(event.target.value)} />
          </label>
          <label>
            Title:
            <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
          </label>
          <div>
            {subtitles.map((subtitle, subtitleIndex) => (
              <div key={subtitleIndex}>
                <label>
                  Subtitle {subtitleIndex + 1}:
                  <input type="text" value={subtitle.text} onChange={(event) => {
                    const updatedSubtitles = [...subtitles];
                    updatedSubtitles[subtitleIndex].text = event.target.value;
                    setSubtitles(updatedSubtitles);
                  }} />
                </label>
                {subtitle.paragraphs.map((paragraph, paragraphIndex) => (
                  <div key={paragraphIndex}>
                    <textarea value={paragraph} onChange={(event) => handleParagraphChange(subtitleIndex, paragraphIndex, event.target.value)} />
                    <input type="file" onChange={(event) => handleImageUpload(event, subtitleIndex, paragraphIndex)} />
                    <button type="button" onClick={() => handleAddParagraph(subtitleIndex)}>Add paragraph</button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="col">
          <button type="button" onClick={handleAddSubtitle}>Add subtitle</button>
          <button type="button" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </div>
      </form>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </div>
  );
};

export default AddPost;
