import React, { useState, useEffect, useMemo } from 'react';
import "./Blog.css";
import SliderImage from "../common/Slider";
import AdvertCard from "../common/AdvertCard";
import ContactForm from "../common/ContactForm";
import { ClipLoader } from 'react-spinners';
import ShareButtons from '../common/SocialShare';
import learn from "../../images/learn.jpg";
import learnin1 from "../../images/learnin1.png";
import learning2 from "../../images/learning2.png";
import learning3 from "../../images/learning3.png";
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { db } from "../firebasekeys";
import { ref, onValue } from 'firebase/database';
import { Helmet } from 'react-helmet';
import Search from './search';
import SearchModel from './searchModel';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const Post = () => {
  const [posts, setPosts] = useState(null);
  const [post, setPost] = useState({});
  const [showForm, setShowForm] = useState(false);
  const { title } = useParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const localpost = JSON.parse(localStorage.getItem("posts"));
    if (localpost !== null) {
      setPost(localpost["posts"].find((p) => p.title === title));
      setPosts(localpost.posts);
    }
    else {
      const postsRef = ref(db, '/blog-posts');
      onValue(postsRef, (snapshot) => {
        const postsData = snapshot.val();
        const post = Object.values(postsData).find(p => p.title === title);
        if (post) {
          setPost(post);
          setPosts(Object.values(postsData));
        }
        else {
          navigate('/blog');
          toast.error("Post not found", { autoClose: 7000 }); // Replaced alert with toast
        }
      });
    }
  }, [title]);

  // get the first image in post to be added to sharelinks
  const firstImageUrls = useMemo(() => {
    return post?.subtitles?.map((subtitle) => {
      const paragraph = subtitle.paragraphs.join("");
      const imgSrcMatch = paragraph.match(/<img[^>]*src=["']([^"']*)["'][^>]*>/);
      if (imgSrcMatch) {
        const imgSrc = imgSrcMatch[1];
        return imgSrc;
      } else {
        return null;
      }
    }).filter(Boolean).map(url => url)[1];
  }, [post]);

  // show form
  const sh = () => {
    window.scrollTo(0, 0);
    setShowForm(true);
  };

  const closeForm = () => setShowForm(false);
  const handleSearch = (value) => {
    setSearchValue(value);
    setShowModal(true);
  };
  const closemodel = () => setShowModal(false);

  return (
    <div className="container">
      {showModal && <SearchModel value={searchValue} onClose={closemodel} />}
      {showForm ? <ContactForm closeForm={closeForm} /> : null}
      <Search onSearch={handleSearch} />
      <div className="postcontainer">
        {post && posts ? (
          <>
            <div className="left">
              <div className='leftad'>
                <AdvertCard clicked={sh} imageUrl={learning2} />
                <AdvertCard clicked={sh} imageUrl={learning3} />
              </div>
            </div>
            <div className="middle">
              <div className='postmaintitle'>
                <h1>{post.title}</h1>
              </div>
              <div className='contractAd'>
                <p onClick={sh}>Contact us now for a quote on how to expand your business visibility online!!!</p>
              </div>
              <div>
                {post.subtitles.map((item, key) => {
                  const toString = item.paragraphs.join('');
                  const paragraph = toString.replace(/<img[^>]*src=["']([^"']*)["'][^>]*>/g, '');
                  const imgSrc = toString.match(/<img[^>]*src=["']([^"']*)["'][^>]*>/g);

                  return (
                    <div className='postContent' key={key}>
                      <p className='postTitle'>{item.text}</p>
                      <p>{paragraph}</p>
                      {imgSrc && <img src={imgSrc[0].match(/src="([^"]+)"/)[1]} alt={`${item.text}`} />}
                    </div>
                  );
                })}
              </div>
              <div className='sharepost'>
                <p>Did you enjoy reading our post? Kindly share!!!</p>
                <ShareButtons url={window.location.href} title={title} description={post.description} imageUrl={firstImageUrls} />
              </div>
              <div className='relatedPost'>
                <div className='postTitle' id='related'>
                  <p>Related Posts</p>
                </div>
                {posts.slice(-5).map((item, key) => {
                  const toString = item.subtitles.map(subtitle => subtitle.paragraphs.join('')).join('');
                  const imgSrc = toString.match(/<img[^>]*src=["']([^"']*)["'][^>]*>/);
                  return (
                    <div className='relateditems' key={key}>
                      <div onClick={() => window.scrollTo(0, 0)} className='releteditemtext'>
                        <Link to={`/blog/${item.title}`}>
                          <h4>{item.title}</h4>
                        </Link>
                        <p>{item.description}</p>
                      </div>
                      {imgSrc && <img src={imgSrc[1]} alt={`${item.text}`} />}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="right">
              <AdvertCard clicked={sh} imageUrl={learn} />
              <AdvertCard clicked={sh} imageUrl={learnin1} />
              <AdvertCard clicked={sh} imageUrl={learning2} />
            </div>
          </>
        ) : (
          <div className='spiner'>
            <ClipLoader color={'#123abc'} loading={true} />
          </div>
        )}
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </div>
  );
};

export default Post;
