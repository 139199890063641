import 'fast-text-encoding';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes as Switch } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Home from './components/home/Home';
import About from './components/about/About';
import Services from './components/services/Services';
//import Projects from './components/projects/Projects';
// import Project from './components/projects/Project';
import Blog from './components/blog/Blog';
import Post from './components/blog/Post';
import AddPost from './components/blog/AddPost';
import Error404 from './components/error/Error404';
import Login from './components/common/Login';
import AuthContext from "./components/context/UserContext";
import Terms from "./components/common/terms&service"
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import the default styles

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  // Function to show a toast notification
  const showToast = () => {
    toast.success('This is a success message!');
  };

  return (
    <>
      {/* ToastContainer is where all your toast notifications will appear */}
      <ToastContainer 
        position="top-center" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />

      <AuthContext.Provider value={currentUser}>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/services" element={<Services />} />
            {/*<Route exact path="/projects" element={<Projects />} />*/}
            {/* <Route path="/projects/:id" element={Project} /> */}
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/blog/:title" element={<Post />} />
            {currentUser ? <Route exact path="/addPost" element={<AddPost />} /> :
              <Route exact path="/Login" element={<Login />} />}
            <Route exact path="/Login" element={<Login />} />
            <Route path='/*' element={<Error404 />} />
          </Switch>
          <Footer />
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
