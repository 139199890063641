import React, { useState } from "react";
import { db } from "../firebasekeys";
import { ref, onValue } from 'firebase/database';
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const Search = (props) => {
  const [keyword, setKeyword] = useState("");
  const [post, setPost] = useState({});
  const [spiner, setSpiner] = useState(false);

  const checkDataBase = () => {
    try {
      if (!keyword) {
        setSpiner(false);
        toast.error("Kindly add some keyword to search", { autoClose: 7000 }); // Replaced alert with toast
      } else {
        const postsRef = ref(db, '/blog-posts');
        onValue(postsRef, (snapshot) => {
          const postsData = snapshot.val();
          const post = Object.values(postsData);
          const realpost = post.filter(item => item.title.toLowerCase().includes(keyword.toLowerCase()));
          if (realpost.length > 0) {
            setPost(realpost);
            props.onSearch(realpost);
            setSpiner(false);
          } else {
            setSpiner(false);
            toast.info("Post not found", { autoClose: 7000 }); // Replaced alert with toast
          }
        });
      }
    } catch (error) {
      toast.error(error.message === "Failed to fetch" ? "Kindly Check your Network Connection and try again!" : error.message, { autoClose: 7000 });
      console.log(error.message);
    }
  };

  return (
    <div className="search-container">
      {
        !spiner ?
          <>
            <input placeholder="Type a keyword. (example... technology)" type="text" onChange={(e) => setKeyword(e.target.value)} />
            <button onClick={() => {
              checkDataBase();
              setSpiner(true);
            }}>Search</button>
          </> :
          <ClipLoader color={'#123abc'} loading={true} />
      }
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} /> {/* Toast container */}
    </div>
  );
};

export default Search;
